// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {Environment} from './type';

export const environment: Environment = {
  sentry_dsn: {
    management: 'https://064383fd91e59d13ff1e758fb5f19ad8@sentry.infra.paperless.io/3',
    submission: 'https://be2f18649f8314e2338f07b58bf7b252@sentry.infra.paperless.io/4',
    render: 'https://95a65227daafed30e9f2fdb1f00e2581@sentry.infra.paperless.io/5'
  },
  stripe_publishable_key: 'pk_test_51H2z9ZAnIX5ZBtuH6rD1D34d6ZJThdhw6G0Z5Rp8e8Na7o01QjF5KDVrtiTXhbHuxuy7L6XqbOC1POKZhXvqSXa0001E62xmcO',
  release: '17f0bb82008d21718588bc35de1d556f891499aa',
  name: 'staging',
  production: true,
  api: {
    core: '/api/',
    iban: '/iban/'
  },
  rendering_api_key: 'uk0aphuk0aic1feiquaiphah4ahloeGhielie8Aiyoo5que9cu8vei7gaeW9aeth0niex7Aishaig4eij0aiTh8ourohcheireikahbiquaeSaiGo7keph0Jeiqu0doo',
  signmeBaseUrl: 'https://cloud-ref.sign-me.de',
  deployUrl: 'https://app.staging.paperless.io',
  websocket: {
    url: 'wss://app.staging.paperless.io/cable',
    channel: {
      bucketChannel: 'API::V1::BucketChannel',
      processRunsChannel: 'API::V1::Streaming::ProcessRunsChannel'
    }
  },
  marketplaceBaseUrl: '/cms',
  marketplaceToken: 'dMF6gAVqbw7e05dgjSN__WXbdblczeXW',
  marketplaceContactSupportWebhookUUID: '9a3849f2-d827-42b4-9f34-c683f4f15d8f',
  validationTemplateUsageFlowId: 'e221f456-e2cc-4e8e-8d5b-ea8da526bda7',
  gtm: {
    id: 'GTM-5TPXFQ2',
    auth: '98BtqYX58hsEI_J5OZgvqQ',
    env: 'env-4',
    resource_path: 'https://gtm.paperless.io/gtm.js'
  },
  intercom_app_id: 'afv7b5ti'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
